import * as React from "react"
import { Frame } from "framer"

export function CustomCSS() {
    return (
        <Frame width="100%" height="auto">
            <style>{`
        a[href^="tel"] {
          color: white; /* This sets the phone number color to white */
          text-decoration: none;
          pointer-events: none;
          cursor: default;
        }
      `}</style>
            {/* Keep the "Support" text separate */}
            <p>
                Support: <a href="tel:+023181616">02 318 16 16</a>
            </p>
        </Frame>
    )
}
